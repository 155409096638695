/* NOTE: DO NOT ADD COLORS WITHOUT EXTREMELY GOOD REASON AND DESIGN REVIEW
 * NOTE: KEEP SYNCRONIZED WITH:
 * frontend/src/metabase/ui/utils/colors.ts
 * frontend/src/metabase/styled-components/containers/GlobalStyles/GlobalStyles.tsx
 * frontend/src/metabase/styled-components/theme/css-variables.ts
 */
:root {
  /* Semantic colors */
  --mb-color-brand: var(--mb-base-color-blue-40);
  --mb-color-brand-light: color-mix(in srgb, var(--mb-color-brand), #fff 80%);
  --mb-color-brand-lighter: color-mix(in srgb, var(--mb-color-brand), #fff 90%);
  --mb-color-brand-alpha-04: color-mix(
    in srgb,
    var(--mb-color-brand) 4%,
    transparent
  );
  --mb-color-brand-alpha-88: color-mix(
    in srgb,
    var(--mb-color-brand) 88%,
    transparent
  );
  --mb-color-border-alpha-30: color-mix(
    in srgb,
    var(--mb-color-border) 30%,
    transparent
  );
  --mb-color-text-white-alpha-85: color-mix(
    in srgb,
    var(--mb-color-text-white) 85%,
    transparent
  );
  --mb-color-bg-black-alpha-60: color-mix(
    in srgb,
    var(--mb-color-bg-black) 60%,
    transparent
  );
  --mb-color-bg-white-alpha-15: color-mix(
    in srgb,
    var(--mb-color-bg-white) 15%,
    transparent
  );
  --mb-color-success: #84bb4c;
  --mb-color-summarize: #88bf4d;
  --mb-color-error: #ed6e6e;
  --mb-color-danger: #ed6e6e;
  --mb-color-text-dark: #4c5773;
  --mb-color-text-medium: #696e7b;
  --mb-color-text-light: #949aab;
  --mb-color-bg-black: #2e353b;
  --mb-color-bg-dark: #93a1ab;
  --mb-color-bg-medium: #edf2f5;
  --mb-color-bg-light: #f9fbfc;
  --mb-color-bg-white: var(--mb-base-color-white);
  --mb-color-bg-error: #ed6e6e55;
  --mb-color-bg-night: #42484e;
  --mb-color-shadow: rgba(0, 0, 0, 0.13);
  --mb-color-border: #eeecec;
  --mb-color-filter: #7172ad;
  --mb-color-focus: var(--mb-base-color-blue-20);
  --mb-color-warning: #f9cf48;
  --mb-color-text-primary: #6666cc;
  --mb-color-text-secondary: #6666cc;
  --mb-color-text-tertiary: #6666cc;
  --mb-color-text-selected: var(--mb-base-color-white);
  --mb-color-text-hover: #6666cc;
  --mb-color-text-brand: #6666cc;
  --mb-color-text-white: var(--mb-base-color-white);
  --mb-color-background: var(--mb-base-color-white);
  --mb-color-background-selected: var(--mb-base-color-brand-40);
  --mb-color-background-hover: var(--mb-base-color-brand-10);
  --mb-color-background-disabled: var(--mb-base-color-gray-10);
  --mb-color-background-inverse: var(--mb-color-bg-black);
  --mb-color-background-brand: var(--mb-base-color-brand-40);
  --mb-color-icon-primary: #6666cc;

  /**
    * Base colors
    *
    * These are the base colors that should never change and never be used
    * directly in any components or CSS properties. They should only be used
    * to define the other semantic colors in this file.
    *
    * For example. This is how you should use the base color ✅
    *
    * .darkTheme {
    *   --mb-color-border: var(--mb-base-color-some-gray);
    * }
    *
    * This is NOT how you should use the base color ❌
    *
    * .someClass {
    *   color: var(--mb-base-color-white);
    * }
    *
    * or
    *
    * <Component someColorProp="var(--mb-base-color-white)" />
    */
  --mb-base-color-white: #fff;

  /* Brand */
  --mb-base-color-brand-70: #6666cc;
  --mb-base-color-brand-60: #6666cc;
  /*   brand */
  --mb-base-color-brand-40: #6666cc;
  --mb-base-color-brand-30: #6666cc;
  --mb-base-color-brand-10: #6666cc;

  /* Blue */
  --mb-base-color-blue-100: #6666cc;
  --mb-base-color-blue-90: #6666cc;
  --mb-base-color-blue-80: #6666cc;
  --mb-base-color-blue-70: #6666cc;
  --mb-base-color-blue-60: #6666cc;
  --mb-base-color-blue-50: #6666cc;
  --mb-base-color-blue-40: #6666cc; /* brand */
  --mb-base-color-blue-30: #6666cc;
  --mb-base-color-blue-20: #6666cc; /* focus */
  --mb-base-color-blue-10: #6666cc; /* baby blue */
  --mb-base-color-blue-5: #6666cc;

  /* Gray */
  /*   bg-black */
  --mb-base-color-gray-100: #121619;
  --mb-base-color-gray-90: #20272b;
  --mb-base-color-gray-80: #313b42;
  --mb-base-color-gray-70: #47545e;
  --mb-base-color-gray-60: #657079;
  --mb-base-color-gray-50: #808991;
  --mb-base-color-gray-40: #929aa1;
  --mb-base-color-gray-30: #b4bcc3;
  --mb-base-color-gray-20: #dbdfe3;
  --mb-base-color-gray-10: #f3f5f7;
  --mb-base-color-gray-5: #fafbfc;
}

.textDefault {
  color: #6666cc;
}

.textBrand,
.textBrandHover:hover {
  color: #6666cc;
}

.textSuccess {
  color: var(--mb-color-success);
}

/* error */

.textError,
.textErrorHover {
  color: var(--mb-color-error);
}

.bgErrorHover:hover {
  background-color: var(--mb-color-error);
}

.bgErrorInput {
  background-color: var(--mb-color-bg-white);
}

.textSlate {
  color: var(--mb-color-text-medium);
}

.textSlateLight {
  color: var(--mb-color-text-light);
}

.textLight {
  color: #6666cc;
}

.textMedium,
.textMediumHover:hover {
  color: #6666cc;
}

.textDark,
.textDarkHover:hover {
  color: #6666cc;
}

.textPrimary {
  color: #6666cc;
}

.textSecondary {
  color: #6666cc;
}

.textTertiary {
  color: #6666cc;
}

.bgLight,
.bgLightHover:hover {
  background-color: var(--mb-color-bg-light);
}

.bgMedium {
  background-color: var(--mb-color-bg-medium);
}

/* white  - move to bottom for specificity since its often used on hovers, etc */
.textWhite,
.textWhiteHover:hover {
  color: #6666cc;
}

.bgWhite {
  background-color: var(--mb-color-bg-white);
}

.bgLightBlue {
  background-color: var(--mb-color-bg-light);
}

.DashboardNight .bgLight {
  background-color: var(--mb-color-bg-black);
}

.DashboardNight .bgMedium {
  background-color: #596269;
}

.DashboardNight .textDark {
  color: var(--mb-color-bg-light);
}
